import React, { useState, useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductPost = (props) => {

    const [pageTitle, setPageTitle] = useState('');
    const [price, setPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [message, setMessage] = useState('');
    const [productCatigory, setProductCatigory] = useState('');

    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 

    /* Send all Files data to parents */
    useEffect(()=>{
        const allInfo = { pageTitle, price, quantity, message, productCatigory };
        setUpdateData(allInfo);
    }, [pageTitle, price, quantity, message, productCatigory]);

    props.setDataContaner(updateData);
    /* End of Send all Files data to parents */

    

    /* Loads the select fileds */
    const productCatigorys = [
        { value: "", name: "Please Select Product Category" },
        { value: "Inverter", name: "Inverter" },
        { value: "Battery", name: "Battery" },
    ];

    const quantitys = [
        { value: "", name: "Please Select Product Quantity" },
        { value: "1", name: "1" },
        { value: "2", name: "2" },
        { value: "3", name: "3" },
        { value: "4", name: "4" },
        { value: "5", name: "5" },
        { value: "6", name: "6" },
        { value: "7", name: "7" },
        { value: "8", name: "8" },
        { value: "9", name: "9" },
        { value: "10", name: "10" },
        { value: "11", name: "11" },
        { value: "12", name: "12" },
        { value: "13", name: "13" },
        { value: "14", name: "14" },
        { value: "15", name: "15" },
        { value: "16", name: "16" },
        { value: "17", name: "17" },
        { value: "18", name: "18" },
        { value: "19", name: "19" },
        { value: "20", name: "20" },
    ];
    /* End of Loads the select fileds */
    
    return (
        <>
            <div className='col-md-9 mb-3'>
                <div className="card">
                    <div className="card-header text-white bg-info p-3"><i className="fa fa-shopping-cart"></i> PRODUCT</div>
                    <div className="card-body">
                        <div className='row m-1'>
                            <div className='col-lg-6'>
                                <div className="form-group mt-3">
                                    <label for="pageTitle" className="col-sm-12">
                                        Product Name
                                    </label>
                                    <div className="col-sm-12">
                                        <input
                                            type="text"
                                            name='pageTitle'
                                            className="form-control"
                                            id="pageTitle"
                                            placeholder="Enter Page Title or Product Name"
                                            value={pageTitle}
                                            onChange={(event) => {
                                                setPageTitle(event.target.value);
                                                
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group mt-3">
                                    <label for="productCatigory" className="col-sm-12">
                                        Product Category
                                    </label>
                                    <div className="col-sm-12">
                                        <select
                                            className="form-select"
                                            id="productCatigory"
                                            name="productCatigory"
                                            value={productCatigory}
                                            onChange={(event) => {
                                                setProductCatigory(event.target.value);
                                                
                                            }}
                                        >
                                            {
                                                productCatigorys.map((item) => {
                                                    return (<option value={item.id} key={item.id}>{item.name}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group mt-3">
                                    <label for="price" className="col-sm-12">
                                        Price
                                    </label>
                                    <div className="col-sm-12">
                                        <input
                                            type="number"
                                            name='price'
                                            className="form-control"
                                            id="price"
                                            placeholder="Enter Product Price"
                                            value={price}
                                            onChange={(event) => {
                                                setPrice(event.target.value);
                                                
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className="form-group mt-3">
                                    <label for="quantity" className="col-sm-12">
                                        Quantity
                                    </label>
                                    <div className="col-sm-12">
                                        <select
                                            className="form-select"
                                            id="quantity"
                                            name="quantity"
                                            value={quantity}
                                            onChange={(event) => {
                                                setQuantity(event.target.value);
                                                
                                            }}
                                        >
                                            {
                                                quantitys.map((item) => {
                                                    return (<option value={item.id} key={item.id}>{item.name}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="form-group mt-3">
                                    <label for="publication" className="col-sm-12">
                                        Publication
                                    </label>
                                    <div className="col-sm-12">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={message}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                //console.log( event );
                                                setMessage(editor.getData());
                                                
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}

export default ProductPost
