import React, { useState, useEffect } from 'react'
import axios from "axios";
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import Crop from "../../components/admin/childComponents/appComponents/Crop";

const Sidebar = () => {
    /* For Image Crop */
    const [returnCrop, setReturnCrop] = useState(null);
    const [cropImage, setCropImage] = useState('');
    const [closeModal, setCloseModal] = useState(false);

    const [staffImage, setStaffImage] = useState('');
    const [admin_type, setAdmin_type] = useState('');
    const [adminId, setAdminId] = useState('');


    useEffect(() => {
        setAdmin_type(localStorage.getItem("admin_type"));
        setStaffImage(localStorage.getItem('admin_passport'));
        setAdminId(localStorage.getItem('admin_id'));
    }, []);

    let image_02 = `http://127.0.0.1:8000/` + staffImage;


    const loadFile = function (event) {
        let myFileUrl = URL.createObjectURL(event.target.files[0]);
        setCropImage(myFileUrl);
        setCloseModal(true);
    }
    useEffect(() => {
        (returnCrop) && (submitUpload());
    }, [returnCrop]);

    async function submitUpload() {

        //event.preventDefault();
        let fileUploadItem
        if (!returnCrop) {
            console.log("No file Selected");
            return swal("Caution", "Please select a file or image to upload", "warning");
        } else {
            fileUploadItem = returnCrop;
            console.log('$$$Next', returnCrop);
        }

        const formData = new FormData();
        formData.append('id', adminId);
        formData.append('uploadFile', fileUploadItem);
        formData.append('myFileUpdate', staffImage);
        formData.append('imagePath', 'upload/admin_passports/');

        await axios.get(`sanctum/csrf-cookie`).then((response) => {
            try {
                axios.post(`api/admin/v1/cameras/item`, formData).then((res) => {
                    if (res.data.status === 200) {

                        /*
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.firstName);
                        */
                        localStorage.setItem('admin_passport', res.data.imagePath);
                        setStaffImage(res.data.imagePath);
                        swal("Success", res.data.message, "success");

                        //navigate('/admin/allpost'); //redirect to the 
                    } else if (res.data.status === 500) {

                        swal("Caution", res.data.message, "warning");
                        //navigate("/register02"); //redirect to the home page
                    } else {
                        swal("Failed", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                    }

                });
            } catch (error) {
                swal("Oops!", "Profile Image Update Failed", "error");
                console.log(error.response.data);
            }
        });
    }

    return (
        <>
            <div>
                {
                    closeModal ? (
                        <Crop imageCropUrl={cropImage} setReturnCrop={setReturnCrop} aspectRatios={1} setCloseModal={setCloseModal} />) : null
                }
            </div>
            <div id="layoutSidenav_nav" >
                <nav className="sb-sidenav accordion sb-sidenav-dark bg-secondary" id="sidenavAccordion">
                    <div className="sb-sidenav-menu">

                        <div className="nav">

                            {/* <div className="sb-sidenav-menu-heading row col-md-12 text-light pb-4">
                                    <div className='col-md-4'>
                                        <img src={image_02} alt="" width="60" height="60" className='rounded-circle' />
                                    </div>
                                    <div className="admin-info col-md-8 text-uppercase mt-1 ">
                                        <div className="fw-normal">{localStorage.getItem("admin_name")}</div><div className='fw-light'>{admin_type}</div>
                                    </div>
                                </div>
                            */}

                            <div className="container">
                                <div className="avatar-upload row col-md-12 text-light">
                                    <div className="col-md-5 ms-0 ps-1">
                                        <div className="avatar-edit">
                                            <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" onChange={loadFile} />
                                            <label for="imageUpload"></label>
                                        </div>
                                        <div className="avatar-preview">

                                            <img src={image_02} alt="" id="imagePreview" width="80" height="80" className='rounded-circle' style={{ border: "white thick solid" }} />

                                        </div>
                                    </div>
                                    <div className="staffSize admin-info col-md-7 text-uppercase mt-1  ms-0 ps-1 pe-0 pt-3">
                                        <div className="fw-normal">{localStorage.getItem("admin_name")}</div><div className='fw-light'>{admin_type}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="sb-sidenav-menu-heading pt-2 bg-info bg-gradient fw-normal text-light fs-6">Core</div>
                            <Link className="nav-link" to="/admin/dashboard">
                                <div className="sb-nav-link-icon text-light"><i className="fas fa-tachometer-alt"></i></div>
                                <div className="col-4 text-light">Dashboard</div>
                            </Link>
                            <div className="sb-sidenav-menu-heading mt-2 pt-2 bg-info bg-gradient fw-normal text-light fs-6">Interface</div>
                            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts">
                                <div className="sb-nav-link-icon text-light"><i className="fas fa-columns"></i></div>

                                <div className="justify-content-start row col-11 text-light">
                                    <div className="col-4">Post</div>
                                    <div className="col-8">
                                        <div className="badge bg-warning rounded-1 float-end fw-normal text-light">2</div>
                                    </div>
                                </div>

                                <div className="sb-sidenav-collapse-arrow text-light"><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className="collapse" id="collapseLayouts" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav">
                                    <Link className="nav-link pt-1 pb-1" to="/admin/post"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> Post</Link>
                                    <Link className="nav-link pt-1 pb-1" to="/admin/allpost"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> All Post</Link>
                                    <Link className="nav-link pt-1 pb-1" to="/admin/students"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> Student</Link>
                                </nav>
                            </div>
                            <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#collapsePages" aria-expanded="false" aria-controls="collapsePages">
                                <div className="sb-nav-link-icon text-light"><i className="fas fa-book-open"></i></div>

                                <div className="justify-content-start row col-11">
                                    <div className="col-4 text-light">Pages</div>
                                    <div className="col-8">
                                        <div className="badge bg-primary rounded-1 float-end fw-normal text-light">4</div>
                                    </div>
                                </div>

                                <div className="sb-sidenav-collapse-arrow text-light"><i className="fas fa-angle-down"></i></div>
                            </Link>
                            <div className="collapse" id="collapsePages" aria-labelledby="headingTwo" data-bs-parent="#sidenavAccordion">
                                <nav className="sb-sidenav-menu-nested nav accordion" id="sidenavAccordionPages">
                                    <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseAuth" aria-expanded="false" aria-controls="pagesCollapseAuth">

                                        <div className="col-4">Authentication</div>
                                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                    </Link>
                                    <div className="collapse" id="pagesCollapseAuth" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                        <nav className="sb-sidenav-menu-nested nav">
                                            <Link className="nav-link pt-1 pb-1" to="login.html"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> Login</Link>
                                            <Link className="nav-link pt-1 pb-1" to="register.html"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> Register</Link>
                                            <Link className="nav-link pt-1 pb-1" to="password.html"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> Forgot Password</Link>
                                        </nav>
                                    </div>
                                    <Link className="nav-link collapsed" to="#" data-bs-toggle="collapse" data-bs-target="#pagesCollapseError" aria-expanded="false" aria-controls="pagesCollapseError">

                                        <div className="col-4">Error</div>
                                        <div className="sb-sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                    </Link>
                                    <div className="collapse" id="pagesCollapseError" aria-labelledby="headingOne" data-bs-parent="#sidenavAccordionPages">
                                        <nav className="sb-sidenav-menu-nested nav">
                                            <Link className="nav-link pt-1 pb-1" to="401.html"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> 401 Page</Link>
                                            <Link className="nav-link pt-1 pb-1" to="404.html"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> 404 Page</Link>
                                            <Link className="nav-link pt-1 pb-1" to="500.html"><i className="fa fa-circle me-1" style={{ fontSize: "7px" }}> </i> 500 Page</Link>
                                        </nav>
                                    </div>
                                </nav>
                            </div>
                            <div className="sb-sidenav-menu-heading mt-2 pt-2 bg-info bg-gradient fw-normal text-light fs-6">Addons</div>
                            <Link className="nav-link" to="charts.html">
                                <div className="sb-nav-link-icon text-light"><i className="fas fa-chart-area"></i></div>
                                <div className="col-4 text-light">Charts</div>
                            </Link>
                            <Link className="nav-link" to="tables.html">
                                <div className="sb-nav-link-icon text-light"><i className="fas fa-table"></i></div>
                                <div className="col-4 text-light">Tables</div>
                            </Link>
                        </div>
                    </div>
                    <div className="sb-sidenav-footer">
                        <div className="small">Logged in as:</div>
                        {admin_type}
                    </div>
                </nav>
            </div>
        </>
    )
}
export default Sidebar;