import React, { useState, useEffect } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const PublicationUpdate = (props) => {

    const [pageTitle, setPageTitle] = useState('');
    const [message, setMessage] = useState('');

    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 

    /* Load all File with data */
    useEffect(() => {
        setPageTitle(props.moreeditpost.pageTitle);
        setMessage(props.moreeditpost.message);
    }, [props.moreeditpost]);
    /* End of Load all File with data */
    
    /* Send all Files data to parents */
    useEffect(()=>{
        const allInfo = { pageTitle, message };
        setUpdateData(allInfo);
    }, [pageTitle, message]);

    props.setDataContaner(updateData);
    /* End of Send all Files data to parents */
 

    return (
        <>
            <div className='col-md-9 mb-3'>
                <div className="card">
                    <div className="card-header text-white bg-info p-3"><i className="fa fa-newspaper"></i> PUBLICATION</div>
                    <div className="card-body">
                        <div className='row m-1'>
                            <div className='col-lg-6'>
                                <div className="form-group mt-3">
                                    <label for="pageTitle" className="col-sm-12">
                                        Page Title
                                    </label>
                                    <div className="col-sm-12">
                                        <input
                                            type="text"
                                            name='pageTitle'
                                            className="form-control"
                                            id="pageTitle"
                                            placeholder="Enter Page Title or Product Name"
                                            value={pageTitle}
                                            onChange={(event) => {
                                                setPageTitle(event.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-12'>
                                <div className="form-group mt-3">
                                    <label for="publication" className="col-sm-12">
                                        Publication
                                    </label>
                                    <div className="col-sm-12">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={message}
                                            onReady={editor => {
                                                // You can store the "editor" and use when it is needed.
                                                console.log('Editor is ready to use!', editor);
                                            }}
                                            onChange={(event, editor) => {
                                                //console.log( event );
                                                setMessage(editor.getData());
                                            }}
                                            onBlur={(event, editor) => {
                                                console.log('Blur.', editor);
                                            }}
                                            onFocus={(event, editor) => {
                                                console.log('Focus.', editor);
                                            }}
                                        />

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicationUpdate
