import React, { useState, useEffect } from 'react'
import axios from "axios";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import ProductPost from "./childComponents/display/ProductPost";
import PublicationPost from "./childComponents/display/PublicationPost";
import PublicationDefualt from "./childComponents/display/PublicationDefualt";
import FileUpload from "./childComponents/display/FileUpload";
import Crop from "./childComponents/appComponents/Crop";

const buttonName = 'Submit';// Submit Button Name

const Post = () => {

    const [loading, setloading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process

    const [pageLayout, setPageLayout] = useState('');
    const [pageCatigory, setPageCatigory] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [dataContaner, setDataContaner] = useState('');

    const [aspectRatios, setAspectRatios] = useState(0);

    /* For data Upload */
    const [returnUpload, setReturnUpload] = useState('');

    /* For Image Crop */
    const [returnCrop, setReturnCrop] = useState('');
    const [cropImage, setCropImage] = useState('');
    const [closeModal, setCloseModal] = useState(false);

    /* select which componet to display */
    let tagName;
    switch (pageLayout) {
        case "Publication":
            tagName = <PublicationPost setDataContaner={setDataContaner} />;
            break;
        case "Product":
            tagName = <ProductPost setDataContaner={setDataContaner} />;
            break;
        default:
            tagName = <PublicationDefualt setDataContaner={setDataContaner} />;
    }
    /* End of select which componet to display */

    /* Loads the select fileds */
    const pageCatigorys = [
        { value: "", name: "Please Select Page Category" },
        { value: "Home", name: "Home" },
        { value: "Contact", name: "Contact" },
        { value: "Product", name: "Product" },
        { value: "About Us", name: "About Us" }
    ];

    const pageLayouts = [
        { value: "", name: "Please Select Page Layout" },
        { value: "Publication", name: "Publication" },
        { value: "Product", name: "Product" },
    ];

    const activeStatuss = [
        { value: "", name: "Please Select Status" },
        { value: "Active", name: "Active" },
        { value: "Draft", name: "Draft" },
        { value: "Delete", name: "Delete" },

    ];
    const aspectRatioss = [
        { value: "", name: "Select the Image Type" },
        { value: 7 / 5, name: "Landscape" },
        { value: 6.5 / 7, name: "Passport" },
        { value: 7 / 7, name: "Product" },
    ];
    /* End of Loads the select fileds */
    async function cropPhotoImage(event) {
        event.preventDefault();
        if ((aspectRatios === 'Please Image Type') || (!returnUpload.myFileUrl)) {

            return swal("Caution", "Please select an image to upload, then select the Image Type", "warning");
        }

        setCropImage(returnUpload.myFileUrl);
        setCloseModal(true);
    }

    async function submitUpload(event) {

        event.preventDefault();

        if (!returnUpload.myFile) {
            console.log("No file Selected");
            return swal("Caution", "Please select a file or image to upload", "warning");

        }
        setBtnLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);

        let fileUploadItem = returnUpload.myFile;

        if (returnCrop) {
            fileUploadItem = returnCrop;


            console.log('$$$Next', returnCrop.fulfilled);
        }

        const formData = new FormData();

        formData.append('uploadFile', fileUploadItem);
        formData.append('myFileUpdate', returnUpload.myFileUpdate);

        formData.append('pageTitle', dataContaner.pageTitle);
        formData.append('message', dataContaner.message);
        formData.append('pageLayout', pageLayout);
        formData.append('pageCatigory', pageCatigory);
        formData.append('activeStatus', activeStatus);


        if (pageLayout === 'Product') {
            formData.append('productCatigory', dataContaner.productCatigory);
            formData.append('price', dataContaner.price);
            formData.append('quantity', dataContaner.quantity);
        }

        await axios.get(`sanctum/csrf-cookie`).then((response) => {
            try {
                axios.post(`api/admin/v1/post_page`, formData).then((res) => {
                    if (res.data.status === 200) {

                        /*
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.firstName);
                        */
                        swal("Success", res.data.message, "success");
                        setBtnLoading(buttonName);
                        softreload();
                        //navigate('/admin/allpost'); //redirect to the 

                    } else if (res.data.status === 500) {

                        swal("Caution", res.data.message, "warning");
                        //navigate("/register02"); //redirect to the home page
                        setBtnLoading(buttonName);
                    } else {
                        swal("Failed", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                        setBtnLoading(buttonName);
                    }

                });
            } catch (error) {
                swal("Oops!", "Publication Failed", "error");
                console.log(error.response.data);
            }
        });
    }

    useEffect(() => {
        setloading(false);
    }, []);

    if (loading) {
        return <div id="loader"></div>;
    }

    return (
        <>
            {closeModal ? (
                <Crop imageCropUrl={cropImage} setReturnCrop={setReturnCrop} aspectRatios={aspectRatios} setCloseModal={setCloseModal} />) : null
            }

            <div className='container-lg-fluid m-3'>

                <form onSubmit={submitUpload} encType="multipart/form-data" >
                    <div className='row'>
                        <div className='col-md-12 mb-3'>
                            <div className="card">
                                <div className="card-header text-white bg-info p-3"><i className="fa fa-newspaper"></i> ADD NEW POST</div>
                                <div className="card-body">
                                    <div className='row m-1'>
                                        <div className='col-lg-3'>
                                            <div className="form-group mt-3">
                                                <label for="pageLayout" className="col-sm-12">
                                                    Page Layout
                                                </label>
                                                <div className="col-sm-12">
                                                    <select
                                                        className="form-select"
                                                        id="pageLayout"
                                                        name="pageLayout"
                                                        value={pageLayout}
                                                        onChange={(event) => {
                                                            setPageLayout(event.target.value);
                                                        }}
                                                    >
                                                        {
                                                            pageLayouts.map((item) => {
                                                                return (<option value={item.id} key={item.id}>{item.name}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-group mt-3">
                                                <label for="pageCatigory" className="col-sm-12">
                                                    Page Category
                                                </label>
                                                <div className="col-sm-12">
                                                    <select
                                                        className="form-select"
                                                        id="pageCatigory"
                                                        name="pageCatigory"
                                                        value={pageCatigory}
                                                        onChange={(event) => {
                                                            setPageCatigory(event.target.value);
                                                        }}
                                                    >
                                                        {
                                                            pageCatigorys.map((item) => {
                                                                return (<option value={item.id} key={item.id}>{item.name}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-group mt-3">
                                                <label for="activeStatus" className="col-sm-12">
                                                    Status
                                                </label>
                                                <div className="col-sm-12">
                                                    <select
                                                        className="form-select"
                                                        id="activeStatus"
                                                        name="activeStatus"
                                                        value={activeStatus}
                                                        onChange={(event) => {
                                                            setActiveStatus(event.target.value);
                                                        }}
                                                    >
                                                        {
                                                            activeStatuss.map((item) => {
                                                                return (<option value={item.id} key={item.id}>{item.name}</option>)
                                                            })
                                                        }

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3'>
                                            <div className="form-group mt-3">

                                                <Link className='btn btn-primary col-2 ms-2 mt-4' onClick={reload} to='/admin/post'><i className='fa fa-refresh'></i></Link>
                                                <button className='btn btn-primary col-6 ms-3 mt-4'><div className=' d-flex flex-row'> <i className="fa fa-power-off m-1 col-3"></i>{btnLoading}</div></button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {tagName}

                        {/* send data to be displayed (post is empty), collect processed result for the parent componet*/}
                        <FileUpload editposts={''} setReturnUpload={setReturnUpload} />
                        <div className='col-md-9 mb-3'></div>
                        <div className='col-md-3 mb-3'>
                            <div className='mb-3'>
                                <select
                                    className="form-select"
                                    id="aspectRatios"
                                    name="aspectRatios"
                                    value={aspectRatios}
                                    onChange={(event) => {
                                        setAspectRatios(event.target.value);
                                    }}
                                >
                                    {
                                        aspectRatioss.map((item) => {
                                            return (<option value={item.id} key={item.id}>{item.name}</option>)
                                        })
                                    }
                                </select>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-warning me-3 col-12"
                                    onClick={cropPhotoImage}
                                ><i className="fa fa-image me-1"> </i>
                                    Crop Photo Image
                                </button>
                            </div>
                        </div>


                    </div>
                </form>
            </div>

        </>
    )

    function softreload() {
        setPageLayout('');
        setPageCatigory('');
        setActiveStatus('');
    }
    function reload() {
        window.location.reload();
    }
}

export default Post
