import React, { useState, useEffect } from 'react';
import axios from "axios";
import swal from "sweetalert";
import ProductUpdate from "../display/ProductUpdate";
import PublicationUpdate from "../display/PublicationUpdate";
import PublicationDefualt from "../display/PublicationDefualt";
import FileUpload from "../display/FileUpload";

const buttonName = 'Save Changes';// Submit Button Name
//const image_01 = `http://127.0.0.1:8000/`;
const ModalPost = (props) => {
    const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process

    const [id, setId] = useState('');
    const [pageLayout, setPageLayout] = useState('');
    const [pageCatigory, setPageCatigory] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [dataContaner, setDataContaner] = useState('');
    const [returnUpload, setReturnUpload] = useState('');

    useEffect(() => {
        setPageLayout(props.editposts.pageLayout);
        setPageCatigory(props.editposts.pageCatigory);
        setActiveStatus(props.editposts.activeStatus);
        setId(props.editposts.id);
    }, [props.editposts]);

    
    /* select which componet to display */
    let tagName;
    switch (pageLayout) {
        case "Publication":
            /* Send the value for edit inputs to grand child, create container to recieve the edithed data inputs to here*/
            tagName = <PublicationUpdate moreeditpost={props.editposts} setDataContaner={setDataContaner} />;
            break;
        case "Product":
            tagName = <ProductUpdate moreeditpost={props.editposts} setDataContaner={setDataContaner} />;
            break;
        default:
            tagName = <PublicationDefualt moreeditpost={props.editposts} setDataContaner={setDataContaner} />;
    }
    /* End of select which componet to display */

    /* Loads the select fileds */
    const pageCatigorys = [
        { value: "", name: "Please Select Page Category" },
        { value: "Home", name: "Home" },
        { value: "Contact", name: "Contact" },
        { value: "Product", name: "Product" },
        { value: "About Us", name: "About Us" }
    ];

    const pageLayouts = [
        { value: "", name: "Please Select Page Layout" },
        { value: "Publication", name: "Publication" },
        { value: "Product", name: "Product" },
    ];

    const activeStatuss = [
        { value: "", name: "Please Select Status" },
        { value: "Active", name: "Active" },
        { value: "Draft", name: "Draft" },
        { value: "Delete", name: "Delete" },

    ];
    /* End of Loads the select fileds */


    async function submitUpload(event) {

        event.preventDefault();

        if (!returnUpload.myFile) {
            console.log("No file Selected");
            return swal("Caution", "Please select a file or image to upload", "warning");

        }
        setBtnLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);

        let formData;
        /* This works with file upload */

        formData = new FormData();
        formData.append('id', id);

        formData.append('uploadFile', returnUpload.myFile);
        formData.append('myFileUpdate', returnUpload.myFileUpdate);

        formData.append('pageTitle', dataContaner.pageTitle);
        formData.append('message', dataContaner.message);
        formData.append('pageLayout', pageLayout);
        formData.append('pageCatigory', pageCatigory);
        formData.append('activeStatus', activeStatus);

        if (pageLayout === 'Product') {
            formData.append('productCatigory', dataContaner.productCatigory);
            formData.append('price', dataContaner.price);
            formData.append('quantity', dataContaner.quantity);
        }

        await axios.get(`sanctum/csrf-cookie`).then((response) => {
            try {
                axios.post(`api/admin/v1/post_pages/item`, formData).then((res) => { //This works with file upload
                    //axios.put("api/admin/v1/post_page/" + id, datas).then((res) => { This works without file upload
                    if (res.data.status === 200) {

                        /*
                        localStorage.setItem('auth_token', res.data.token);
                        localStorage.setItem('auth_name', res.data.firstName);
                        */
                        swal("Success", res.data.message, "success");
                        setBtnLoading(buttonName);
                        //window.location.reload();
                        props.setCloseModel(true);
                        
                        //navigate('/admin/allpost'); //redirect to the 

                    } else if (res.data.status === 500) {

                        swal("Caution", res.data.message, "warning");
                        //navigate("/register02"); //redirect to the home page
                        setBtnLoading(buttonName);
                    } else {
                        swal("Failed", res.data.message, "warning");
                        console.log(res.data.message);
                        console.log(res.data.validation_error);
                        setBtnLoading(buttonName);
                    }

                });
            } catch (error) {
                swal("Oops!", "Student Registation Failed", "error");
                console.log(error.response.data);
            }
        });
    }

    return (
        <>

            <form onSubmit={submitUpload} encType="multipart/form-data" >
                <div className='row'>
                    <div className='col-md-12 mb-3'>
                        <div className="card">
                            <div className="card-header text-white bg-info p-3"><i className="fa fa-newspaper"></i> EDIT POST DEFINITION </div>
                            <div className="card-body">
                                <div className='row m-1'>



                                    <div className='col-lg-3'>
                                        <div className="form-group mt-3">
                                            <label for="pageLayout" className="col-sm-12">
                                                Page Layout
                                            </label>
                                            <div className="col-sm-12">
                                                <select
                                                    className="form-select"
                                                    id="pageLayout"
                                                    name="pageLayout"
                                                    value={pageLayout}
                                                    onChange={(event) => {
                                                        setPageLayout(event.target.value);
                                                    }}
                                                >
                                                    {
                                                        pageLayouts.map((item) => {
                                                            return (<option value={item.id} key={item.id}>{item.name}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="form-group mt-3">
                                            <label for="pageCatigory" className="col-sm-12">
                                                Page Category
                                            </label>
                                            <div className="col-sm-12">
                                                <select
                                                    className="form-select"
                                                    id="pageCatigory"
                                                    name="pageCatigory"
                                                    value={pageCatigory}
                                                    onChange={(event) => {
                                                        setPageCatigory(event.target.value);
                                                    }}
                                                >
                                                    {
                                                        pageCatigorys.map((item) => {
                                                            return (<option value={item.id} key={item.id}>{item.name}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="form-group mt-3">
                                            <label for="activeStatus" className="col-sm-12">
                                                Status
                                            </label>
                                            <div className="col-sm-12">
                                                <select
                                                    className="form-select"
                                                    id="activeStatus"
                                                    name="activeStatus"
                                                    value={activeStatus}
                                                    onChange={(event) => {
                                                        setActiveStatus(event.target.value);
                                                    }}
                                                >
                                                    {
                                                        activeStatuss.map((item) => {
                                                            return (<option value={item.id} key={item.id}>{item.name}</option>)
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3'>
                                        <div className="form-group mt-3">
                                            <button className='btn btn-primary col-9 ms-3 mt-4'><div className=' d-flex flex-row'> <i className="fa fa-power-off m-1 col-3"></i>{btnLoading}</div></button>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                    {tagName}
                    {/* send data to be displayed, collect processed result for the parent componet*/}
                    <FileUpload editposts={props.editposts} setReturnUpload={setReturnUpload} />
                </div>
            </form>
        </>)

}
export default ModalPost
