import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import ModalPost from "./ModalPost";

const Modal = (props) => {

    const [showModel, setShowModel] = useState(false);// activates the popup modal
    const [closeModel, setCloseModel] = useState(false);// closes the popup modal
    const closeModalRef = useRef();
    
    useEffect(() => {
        setShowModel(props.trigger);
    }, [props.trigger]);
    //props.editpost

    let tagName;

    switch (props.modelType) {
        case "ModalPost":
            tagName = <ModalPost editposts={props.editpost} setCloseModel={setCloseModel} />;
            break;

        default:
            tagName = <ModalPost editposts={props.editpost} setCloseModel={setCloseModel} />;
    }

    if (closeModel === true) {
        closeModalRef.current?.click();
        setCloseModel(false);
    }

    return (showModel) ? (
        <>
            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Launch demo modal
            </button> */}
            <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{props.titles}</h5>
                            <button type="button" className="close" data-bs-dismiss="modal" ref={closeModalRef} aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='container-lg-fluid m-3'>


                                {tagName}


                            </div>
                        </div>
                        <div className="modal-footer">
                            <Link type="button" className="btn btn-secondary" to="#" data-bs-dismiss="modal">Close</Link>
                        </div>

                    </div>
                </div>
            </div >

        </>
    ) : "";

}

export default Modal
