import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
/*
  NOCAPTCHA_SITEKEY: 6LdDeq8pAAAAAIJLTElH9VdUzLVMNR6X4k5zMJ_F
  NOCAPTCHA_SECRET: 6LdDeq8pAAAAAFJKdY4KgvDhrJEA8RkZPdQ-fXDM
*/
const siteKeys = "6LdDeq8pAAAAAIJLTElH9VdUzLVMNR6X4k5zMJ_F";
const buttonName = 'Reset Password';
const Forgotpassword01 = () => {
  const navigate = useNavigate("");

  const [email, setEmail] = useState();
  const [reCaptcha, setReCaptcha] = useState(null);
  const [loading, setLoading] = useState(buttonName); //to show loading process

  function onChange(value) {
    setReCaptcha(value);
    console.log("Captcha value:", value);
  }

  async function resetPassword(event) {
    event.preventDefault();
    setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    const datas = {
      email: email,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios
          .post("api/admin/v1/forgot_password/validate", datas)
          .then((res) => {
            if (res.data.status === 200) {
              console.log(res.data.message);

              localStorage.setItem("auth_email", res.data.authEmail);
              localStorage.setItem("auth_phone", res.data.authPhone);
              localStorage.setItem("firstName", res.data.firstName);
              localStorage.setItem("lastName", res.data.lastName);
              /**/
              swal("Success", res.data.message, "success");
setLoading(buttonName);
              navigate("/forgotpassword02"); //redirect to the home page
            } else if (res.data.status === 501) {
              swal("Caution", res.data.message, "warning");
              navigate("/forgotpassword02"); //redirect to the home page
              setLoading(buttonName);
            } else {
              swal("Warning", res.data.message, "warning");
              console.log(res.data.message);
              console.log(res.data.validation_error);
              setLoading(buttonName);
            }
          });
      } catch (error) {
        swal("Oops!", "Validation Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <div className="card shadow-lg border-0 rounded-lg mt-5">
            <div className="card-header">
              <h3 className="text-center font-weight-light my-2">
                Forgot Password
              </h3>
            </div>
            <div className="card-body">
              <div className="small mb-3 text-muted">
                Enter your email address below and we'll send you password reset
                instructions.
              </div>
              <form>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    required
                    id="email"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder="name@example.com"
                  />
                  <label for="email">Email address</label>
                </div>
                <div className="form-group">
                  <ReCAPTCHA sitekey={siteKeys} onChange={onChange} />
                </div>
                <div className="form-group">
                  <div
                    className="cf-turnstile"
                    data-sitekey="{{Session::get('captch_key')}}"
                    data-callback="javascriptCallback"
                  ></div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                  <Link className="small" to="/login">
                    Return to login
                  </Link>
                  <button
                    disabled={!reCaptcha}
                    className="btn btn-primary"
                    onClick={resetPassword}
                  >
                    {loading}
                  </button>
                </div>
              </form>
            </div>
            <div className="card-footer text-center py-3">
              <div className="small">
                <Link to="/register01">Need an account? Sign up!</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Forgotpassword01;
