import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

/*
  NOCAPTCHA_SITEKEY: 6LdDeq8pAAAAAIJLTElH9VdUzLVMNR6X4k5zMJ_F
  NOCAPTCHA_SECRET: 6LdDeq8pAAAAAFJKdY4KgvDhrJEA8RkZPdQ-fXDM
  */
const siteKeys = "6LdDeq8pAAAAAIJLTElH9VdUzLVMNR6X4k5zMJ_F";
const buttonName = 'Login';
const Login = () => {
  const navigate = useNavigate("");
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [rememberLogin, setRememberLogin] = useState();
  const [reCaptcha, setReCaptcha] = useState(null);
  const [loading, setLoading] = useState(buttonName); //to show loading process

  function onChange(value) {
    setReCaptcha(value);
    console.log("Captcha value:", value);
  }
  async function submitForm(event) {
    event.preventDefault();
    setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    const datas = {
      email: email,
      password: password,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/admin/v1/login", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);

            localStorage.setItem("admin_name", res.data.admin_name);
            localStorage.setItem("admin_authority", res.data.admin_authority);
            localStorage.setItem("admin_type", res.data.admin_type);
            localStorage.setItem("admin_status", res.data.admin_status);
            localStorage.setItem("admin_passport", res.data.admin_passport);
            localStorage.setItem("admin_signature", res.data.admin_signature);
            localStorage.setItem("admin_id", res.data.admin_id);
            localStorage.setItem("auth_token", res.data.token);
            localStorage.setItem("auth_name", res.data.admin_name);
            /*
              let resp = axios.get("api/user");
              console.log(resp.data, '$$$444');
            */
            swal("Login Successful", res.data.message, "success");
            setLoading(buttonName);
            navigate("/admin/dashboard"); //redirect to the Dashboard page
          } else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
            setLoading(buttonName);
          }
        });
      } catch (error) {
        swal("Oops!", "Login Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <div className="card shadow-lg border-0 rounded-lg mt-5">
            <div className="card-header">
              <h3 className="text-center font-weight-light my-2">Login</h3>
            </div>
            <div className="card-body">
              <form>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                  <label for="email">Email address</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="password"
                    type="password"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    placeholder="Password"
                  />
                  <label for="password">Password</label>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    id="rememberLogin"
                    type="checkbox"
                    value={rememberLogin}
                    onChange={(event) => {
                      setRememberLogin(event.target.value);
                    }}
                  />
                  <label className="form-check-label" for="rememberLogin">
                    Remember Password
                  </label>
                </div>

                <div className="form-group">
                  <ReCAPTCHA sitekey={siteKeys} onChange={onChange} />
                </div>

                <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                  <Link className="small" to="/forgotpassword01">
                    Forgot Password?
                  </Link>
                  <button
                    disabled={!reCaptcha}
                    className="btn btn-primary"
                    onClick={submitForm}
                  >
                    {loading}
                  </button>
                </div>
              </form>
            </div>
            <div className="card-footer text-center py-3">
              <div className="small">
                <Link to="/register01">Need an account? Sign up!</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
