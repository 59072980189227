import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { Outlet, Navigate, useNavigate } from "react-router-dom";

const AdminPrivateRoutes = () => {
  /*const navigate = useNavigate("");
  
  //let auth = {'token':false};
  
  let auth = localStorage.getItem("auth_token");
  let item = <Navigate to="/login" />;
  if (auth) {
    item = <Outlet />;
  } else {
    swal("Unauthorized Attempt!!", "You are been warn!", "warning");
  }

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 403) { //Access Denied
        swal("Forbedden!", error.response.data.message, "warning");
        navigate("/admin/dashboard"); //redirect to the Login page
      }else if (error.response.status === 404) { //Page not found
        swal("404 Error!", 'url Page Not Found', "warning");
        navigate("/admin/dashboard"); //redirect to the Login page
      }
      return Promise.reject(error);
    }
  );

  return item;
*/

  const navigate = useNavigate("");
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    try {
      axios.get("api/admin/v1/checkingIfAuthenticated").then((res) => {
        if (res.data.status === 200) {
          setAuthenticated(true);
        }
        setloading(false);
      });
    } catch (error) {
      console.log(error.toJSON());
    }
    setAuthenticated(false);
  }, []);

  axios.interceptors.response.use(undefined, (error) => {
    if (error.response.status === 401) {
      swal("Unauthorized Attempt! Logout!", error.response.data.message, "warning");
      navigate("/login"); //redirect to the Login page
    } else if (error.response.status === 403) {
      //Access Denied
      swal("Forbedden!", error.response.data.message, "warning");
      navigate("/admin/dashboard"); //redirect to the Dashboard page
    } else if (error.response.status === 404) {
      //Page not found
      swal("404 Error!", error.response.data.message, "warning");
      navigate("/admin/dashboard"); //redirect to the Dashboard page
    }

    return Promise.reject(error);
  });

  if (loading) {
    return <div id="loader"></div>;
  }

  return authenticated ? <Outlet /> : <Navigate to="/login" />;
  /**/
};

export default AdminPrivateRoutes;
