import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

/*
  NOCAPTCHA_SITEKEY: 6LdDeq8pAAAAAIJLTElH9VdUzLVMNR6X4k5zMJ_F
  NOCAPTCHA_SECRET: 6LdDeq8pAAAAAFJKdY4KgvDhrJEA8RkZPdQ-fXDM
  */
const siteKeys = "6LdDeq8pAAAAAIJLTElH9VdUzLVMNR6X4k5zMJ_F";
const buttonName = 'Reset Password';
const Forgotpassword02b = () => {
  const { otp } = useParams();
  const navigate = useNavigate("");

  const [otpToken, setOtptoken] = useState({ otp }.otp);
  const [email, setEmail] = useState();
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [password, setPassword] = useState();
  const [repeatpassword, setRepeatpassword] = useState();
  const [reCaptcha, setReCaptcha] = useState(null);
  const [agree, setAgree] = useState('on');
  const [loading, setLoading] = useState(buttonName); //to show loading process

  function onChange(value) {
    setReCaptcha(value);
    console.log("Captcha value:", value);
  }

  /* load the user email and phone number automatically */
  useEffect((otpToken) => {
    (async () => await tableLoad(otpToken))();
  });

  async function tableLoad(otp) {
    const result = await axios.get("api/admin/v1/verifyotp/" + otpToken);
    setEmail(result.data.email);
    setLastname(result.data.lastName);
    setFirstname(result.data.firstName);
    console.log(result.data.email);
  }
  /* End of load the user email and phone number automatically */

  async function resetPassword(event) {
    event.preventDefault();
    setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    const datas = {
      otpToken: otpToken,
      //firstName: firstName,
      //lastName: lastName,
      email: email,
      password: password,
      repeatpassword: repeatpassword,
      //agree: agree,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/admin/v1/forgot_password/create", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);
            /*
              localStorage.setItem('auth_token', res.data.token);
              localStorage.setItem('auth_name', res.data.firstName);
              */
            swal("Success", res.data.message, "success");
setLoading(buttonName);
            navigate("/login"); //redirect to the home page
          } else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
            setLoading(buttonName);
          }
        });
      } catch (error) {
        swal("Oops!", "Registration Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <div className="card shadow-lg border-0 rounded-lg mt-5">
            <div className="card-header">
              <h3 className="text-center font-weight-light my-2">
                Forgot Password
              </h3>
            </div>
            <div className="card-body">
              <div className="small mb-3 text-muted">
                Enter your email new password and we will reset it for you.
              </div>
              <form>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="otptoken"
                        type="text"
                        value={otpToken}
                        onChange={(event) => {
                          setOtptoken(event.target.value);
                        }}
                        placeholder="Enter Paste the OTP"
                      />
                      <label for="otptoken">OTP</label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input
                      disabled
                        className="form-control"
                        required
                        id="email"
                        type="email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder="name@example.com"
                      />
                      <label for="email">Email address</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input
                      disabled
                        className="form-control"
                        id="firstName"
                        type="text"
                        value={firstName}
                        onChange={(event) => {
                          setFirstname(event.target.value);
                        }}
                        placeholder="Enter your first name"
                      />
                      <label for="email">First name</label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input
                      disabled
                        className="form-control"
                        id="lastName"
                        type="text"
                        value={lastName}
                        onChange={(event) => {
                          setLastname(event.target.value);
                        }}
                        placeholder="Enter your last name"
                      />
                      <label for="email">Last name</label>
                    </div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        required
                        id="password"
                        type="password"
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                        placeholder="Create a password"
                      />
                      <label for="email">Password</label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        required
                        id="repeatpassword"
                        value={repeatpassword}
                        onChange={(event) => {
                          setRepeatpassword(event.target.value);
                        }}
                        type="password"
                        placeholder="Confirm password"
                      />
                      <label for="email">Confirm Password</label>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      id="agree"
                      type="checkbox"
                      name="agree"
                      value={agree}
                      onChange={(event) => {
                        setAgree(event.target.value);
                      }}
                    />
                    <label className="form-check-label" for="agree">
                      I agree the terms and policy
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <ReCAPTCHA sitekey={siteKeys} onChange={onChange} />
                </div>
                <div className="d-flex align-items-center justify-content-between mt-4 mb-0">
                  <Link className="small" to="/login">
                    Return to login
                  </Link>
                  <button
                    disabled={!reCaptcha}
                    className="btn btn-primary"
                    onClick={resetPassword}
                  >
                    {loading}
                  </button>
                </div>
              </form>
            </div>
            <div className="card-footer text-center py-3">
              <div className="small">
                <Link to="/register01">Need an account? Sign up!</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Forgotpassword02b;
