import React, { useState, useEffect } from 'react'

const FileUpload = (props) => {


    const [dropZoneClick, setDropZoneClick] = useState(0);
    const [updateData, setUpdateData] = useState(''); // used to return the data value to the parent 

    const [mydbFile, setMydbFile] = useState(null);
    const [myFile, setMyFile] = useState();
    const [myFileUpdate, setMyFileUpdate] = useState();
    const [myFileUrl, setMyFileUrl] = useState();
    
    useEffect(() => {

        setMyFile(props.editposts.uploadFile);//upload/images/1713119368.jpeg
        setMydbFile(props.editposts.uploadFile);
        setMyFileUpdate(props.editposts.uploadFile);
    }, [props.editposts]);

    /* Displays the upload file */
    useEffect(() => {
        (async () => await dropZoneUpload())();
    }, [mydbFile, dropZoneClick, myFile]);
    /* End of Displays the upload file */

    /* Reload the useEffect so the javascript will work on the Dom */
    useEffect(() => {
        
        const allInfo = { myFile, myFileUpdate, myFileUrl };
        setUpdateData(allInfo);
    }, [myFile, myFileUpdate, myFileUrl]);
    /* End of Reload the useEffect so the javascript will work on the Dom */

    props.setReturnUpload(updateData);

    return (
        <>
            <div className='col-md-3 mb-3'>

                <div className="card">
                    <div className="card-header text-white bg-info p-3"><i className='fa fa-image'> </i> IMAGE SETTINGS</div>
                    <div className="card-body">
                        <div className='row m-1'>
                            <div className='col-lg-12'>
                                <div className="form-group row">

                                    <div className="drop-zone">
                                        <span className="drop-zone__prompt">
                                            Click here to upload File/ Image
                                        </span>
                                        <input
                                            accept="image/png, image/jpeg, image/jpg, application/pdf"
                                            type="file"
                                            name="uploadFile"
                                            onChange={(event) => {
                                                setMyFile(event.target.files[0]);
                                                setMyFileUrl(URL.createObjectURL(event.target.files[0]));
                                                setDropZoneClick(1);
                                                setMydbFile(null);
                                            }}
                                            className="drop-zone__input"
                                        />

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
    async function dropZoneUpload() {

        document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
            const dropZoneElement = inputElement.closest(".drop-zone");
            /* Created By Chuks */
            if (mydbFile !== null) {
                loadThumbnail(dropZoneElement, mydbFile);
            }
            /* End of Created By Chuks */
            //alert(myFile);
            dropZoneElement.addEventListener("click", (e) => {
                inputElement.click();
            });

            inputElement.addEventListener("change", (e) => {
                if (inputElement.files.length) {
                    updateThumbnail(dropZoneElement, inputElement.files[0]);
                }
            });
            /*
                        dropZoneElement.addEventListener("dragover", (e) => {
                            e.preventDefault();
                            dropZoneElement.classList.add("drop-zone--over");
                        });
            
                        ["dragleave", "dragend"].forEach((type) => {
                            dropZoneElement.addEventListener(type, (e) => {
                                dropZoneElement.classList.remove("drop-zone--over");
                            });
                        });
            */
            dropZoneElement.addEventListener("drop", (e) => {
                e.preventDefault();

                if (e.dataTransfer.files.length) {
                    inputElement.files = e.dataTransfer.files;
                    updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
                }

                dropZoneElement.classList.remove("drop-zone--over");
            });
        });

        /**
         * Updates the thumbnail on a drop zone element.
         *
         * @param {HTMLElement} dropZoneElement
         * @param {File} file
         */

        /* Created By Chuks */
        function loadThumbnail(dropZoneElement, myFile) {
            if (myFile) {
                let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");
                if (dropZoneElement.querySelector(".drop-zone__prompt")) {
                    dropZoneElement.querySelector(".drop-zone__prompt").remove();
                }
                if (!thumbnailElement) {
                    thumbnailElement = document.createElement("div");
                    thumbnailElement.classList.add("drop-zone__thumb");
                    dropZoneElement.appendChild(thumbnailElement);
                }
                thumbnailElement.dataset.label = myFile;
                let filePath = `http://127.0.0.1:8000/` + myFile;

                thumbnailElement.style.backgroundImage = `url('${filePath}')`;
            }
        }
        /* End of Created By Chuks */

        function updateThumbnail(dropZoneElement, file) {
            let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

            // First time - remove the prompt
            if (dropZoneElement.querySelector(".drop-zone__prompt")) {
                dropZoneElement.querySelector(".drop-zone__prompt").remove();
            }

            // First time - there is no thumbnail element, so lets create it
            if (!thumbnailElement) {
                thumbnailElement = document.createElement("div");
                thumbnailElement.classList.add("drop-zone__thumb");
                dropZoneElement.appendChild(thumbnailElement);
            }

            thumbnailElement.dataset.label = file.name;

            // Show thumbnail for image files
            if (file.type.startsWith("image/")) {
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.onload = () => {
                    thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
                };
            } else {
                thumbnailElement.style.backgroundImage = null;
            }
        }
    }
}

export default FileUpload
