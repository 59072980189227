import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";

/*
  NOCAPTCHA_SITEKEY: 6LdDeq8pAAAAAIJLTElH9VdUzLVMNR6X4k5zMJ_F
  NOCAPTCHA_SECRET: 6LdDeq8pAAAAAFJKdY4KgvDhrJEA8RkZPdQ-fXDM
  */
const siteKeys = "6LdDeq8pAAAAAIJLTElH9VdUzLVMNR6X4k5zMJ_F";
const buttonName = 'Create An Account';

const Register02 = () => {
  const { otp } = useParams();
  const navigate = useNavigate("");

  const [otpToken, setOtptoken] = useState({ otp }.otp);
  const [firstName, setFirstname] = useState();
  const [lastName, setLastname] = useState();
  const [sex, setSex] = useState();
  const [title, setTitle] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [repeatpassword, setRepeatpassword] = useState();
  const [reCaptcha, setReCaptcha] = useState(null);
  const [agree, setAgree] = useState();
  const [loading, setLoading] = useState(buttonName); //to show loading process

  function onChange(value) {
    setReCaptcha(value);
    console.log("Captcha value:", value);
  }

  /* load the user email and phone number automatically */
  useEffect((otpToken) => {
    (async () => await tableLoad(otpToken))();
  });

  async function tableLoad(otp) {
    const result = await axios.get("api/admin/v1/verifyotp/" + otpToken);
    setEmail(result.data.email);
    setPhone(result.data.phone);
    console.log(result.data.email);
  }

  /* End of load the user email and phone number automatically */
  async function createAccount(event) {
    event.preventDefault();
    setLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);

    const datas = {
      otpToken: otpToken,
      firstName: firstName,
      lastName: lastName,
      sex: sex,
      title: title,
      email: email,
      phone: phone,
      password: password,
      repeatpassword: repeatpassword,
      agree: agree,
    };

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/admin/v1/register/create", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);
            /*
              localStorage.setItem('auth_token', res.data.token);
              localStorage.setItem('auth_name', res.data.firstName);
              */
            swal("Success", res.data.message, "success");
            navigate("/login"); //redirect to the home page
            setLoading(buttonName);
          } else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
            setLoading(buttonName);
          }
        });
      } catch (error) {
        swal("Oops!", "Registration Failed", "error");
        console.log(error.response.data);
        setLoading(buttonName);
      }
    });
    
  }
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-7">
          <div className="card shadow-lg border-0 rounded-lg mt-5">
            <div className="card-header">
              <h3 className="text-center font-weight-light my-2">
                Create An Account
              </h3>
            </div>
            <div className="card-body">
              <form>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-floating mb-3 mb-md-0">
                      <input
                        className="form-control"
                        id="firstName"
                        type="text"
                        value={firstName}
                        onChange={(event) => {
                          setFirstname(event.target.value);
                        }}
                        placeholder="Enter your first name"
                      />
                      <label for="firstName">First name</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        id="lastName"
                        type="text"
                        value={lastName}
                        onChange={(event) => {
                          setLastname(event.target.value);
                        }}
                        placeholder="Enter your last name"
                      />
                      <label for="lastName">Last name</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-floating mb-3 mb-md-0">
                      <select
                        name="sex"
                        id="sex"
                        className="form-control"
                        value={sex}
                        onChange={(event) => {
                          setSex(event.target.value);
                        }}
                      >
                        <option value="">-- Select a Gender --</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>

                      <label for="sex">Gender</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <select
                        name="title"
                        id="title"
                        className="form-control"
                        value={title}
                        onChange={(event) => {
                          setTitle(event.target.value);
                        }}
                      >
                        <option value="">-- Select a Title --</option>
                        <option value="Miss">Miss</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Mr">Mr</option>
                      </select>

                      <label for="title">Title</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-floating mb-3 mb-md-0">
                      <input
                        className="form-control"
                        required
                        disabled
                        id="email"
                        type="text"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        placeholder="Enter your Email"
                      />
                      <label for="email">Email</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        required
                        disabled
                        id="phone"
                        type="text"
                        value={phone}
                        onChange={(event) => {
                          setPhone(event.target.value);
                        }}
                        placeholder="Enter your Phone Number"
                      />
                      <label for="phone">Phone Number</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-floating mb-3 mb-md-0">
                      <input
                        className="form-control"
                        required
                        id="password"
                        type="password"
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                        placeholder="Create a password"
                      />
                      <label for="password">Password</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3 mb-md-0">
                      <input
                        className="form-control"
                        required
                        id="repeatpassword"
                        value={repeatpassword}
                        onChange={(event) => {
                          setRepeatpassword(event.target.value);
                        }}
                        type="password"
                        placeholder="Confirm password"
                      />
                      <label for="repeatpassword">Confirm Password</label>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-floating mb-3 mb-md-0">
                      <input
                        className="form-control"
                        id="otpToken"
                        type="text"
                        value={otpToken}
                        onChange={(event) => {
                          setOtptoken(event.target.value);
                        }}
                        placeholder="Enter Paste the OTP"
                      />
                      <label for="otpToken">OTP</label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        id="agree"
                        type="checkbox"
                        name="agree"
                        value="on"
                        onChange={(event) => {
                          setAgree(event.target.value);
                        }}
                      />
                      <label className="form-check-label" for="agree">
                        I agree the terms and policy
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <ReCAPTCHA sitekey={siteKeys} onChange={onChange} />
                </div>

                <div className="mt-4 mb-0">
                  <div className="d-grid">
                    <button
                      disabled={!reCaptcha}
                      className="btn btn-primary btn-block"
                      onClick={createAccount}
                    
                    >
                      {loading}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="card-footer text-center py-3">
              <div className="small">
                <Link to="/login">Have an account? Go to login</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register02;
