import React from "react";
import "../../assets/admin/css/styles.css";

import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

import Dashboard from "../../components/admin/Dashboard";
import Students from "../../components/admin/Students";
import Post from "../../components/admin/Post";
import AllPost from "../../components/admin/AllPost";

const Masterwebpage = (props) => {
  let tagName;

  switch (props.name) {
    case "Dashboard":
      tagName = <Dashboard />;
      break;
    case "Post":
      tagName = <Post />;
      break;
    case "AllPost":
      tagName = <AllPost />;
      break;
    case "Students":
      tagName = <Students />;
      break;
    default:
      tagName = <Dashboard />;
  }

  return (
    <div className="sb-nav-fixed">
      <Navbar />
      <div id="layoutSidenav">
        <Sidebar />
        <div id="layoutSidenav_content">
          <main>{tagName}</main>
          <Footer />
        </div>
      </div>
    </div>
  );
};
export default Masterwebpage;
