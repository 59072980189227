import React from "react";
import Footer from "./Footer";
//import { useNavigate } from "react-router-dom";
import background from "../../assets/admin/assets/img/shopping_background.jpg";

import Login from "../../components/authAdmin/Login";
import Register01 from "../../components/authAdmin/Register01";
import Register02 from "../../components/authAdmin/Register02";
import Register02b from "../../components/authAdmin/Register02b";
import Forgotpassword01 from "../../components/authAdmin/Forgotpassword01";
import Forgotpassword02 from "../../components/authAdmin/Forgotpassword02";
import Forgotpassword02b from "../../components/authAdmin/Forgotpassword02b";

const Masterauthwebpage = (props) => {
  //const navigate = useNavigate("");
  let tagName;
/*
  if (localStorage.getItem("auth_token")) {// redirect you to the dashboard if an auth_toten is created
    navigate("/admin/dashboard");
  }*/

  switch (props.name) {
    case "Login":
      tagName = <Login />;
      break;
    case "Register01": 
      tagName = <Register01 />;
      break;
    case "Register02":
      tagName = <Register02 />;
      break;
      case "Register02b":
        tagName = <Register02b />;
        break;
    case "Forgotpassword01":
      tagName = <Forgotpassword01 />;
      break;
    case "Forgotpassword02":
      tagName = <Forgotpassword02 />;
      break;
      case "Forgotpassword02b":
        tagName = <Forgotpassword02b />;
        break;
    default:
      tagName = <Login />;
  }

  return (
    <div
      className="bg-primary"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
      }}
    >
      <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
          <main>{tagName}</main>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default Masterauthwebpage;
