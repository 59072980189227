import React from "react";
import { Routes, Route } from "react-router-dom";
import Masterwebpage from "./layouts/admin/Masterwebpage";
import Masterauthwebpage from "./layouts/authAdmin/Masterauthwebpage";
import axios from "axios";
import AdminPrivateRoutes from "./AdminPrivateRoutes";

//import "bootstrap/dist/css/bootstrap.min.css";

/*
 Used for post man: http://127.0.0.1:3000/
//This would make sure that all transactions are in json formate (note good for file upload)
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";

//This would request for authorization before accessing the application. Good for API interface
axios.defaults.headers.common = {
  //Authorization: "Bearer 1|M6YQDupH3ILtPUG1Rg9T8qdgi0rDLJVoZe1ijo4eeed986d4",
  "Access-Control-Allow-Origin": "https://admin.cviewsoft.com.ng", //http://127.0.0.1:3000 or *
  "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
  "Access-Control-Allow-Credentials": "true",
};
*/

//axios.defaults.baseURL = "http://127.0.0.1:8000/";
axios.defaults.baseURL = "https://bend.cviewsoft.com.ng/";

axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("auth_token");
  config.headers.Authorization = token
    ? `Bearer ${token}`
    : "";
  return config;
});
function App() {

  return (
    <div className="App">
      <Routes>
        {/* Admin Login Section */}
        <Route path="/" element={<Masterauthwebpage name="Login" />} />
        <Route path="/login" element={<Masterauthwebpage name="Login" />} />
        <Route path="/logout" element={<Masterauthwebpage name="Login" />} />
        <Route
          path="/register01"
          element={<Masterauthwebpage name="Register01" />}
        />
        <Route
          path="/register02"
          element={<Masterauthwebpage name="Register02" />}
        />
        <Route
          path="/register02/:otp"
          element={<Masterauthwebpage name="Register02b" />}
        />
        <Route
          path="/forgotpassword01"
          element={<Masterauthwebpage name="Forgotpassword01" />}
        />
        <Route
          path="/forgotpassword02"
          element={<Masterauthwebpage name="Forgotpassword02" />}
        />
        <Route
          path="/forgotpassword02/:otp"
          element={<Masterauthwebpage name="Forgotpassword02b" />}
        />
        {/* End of Admin Login Section */}

        {/* Admin Authoried Access */}

        <Route element={<AdminPrivateRoutes />}>
          <Route
            path="/admin/post"
            element={<Masterwebpage name="Post" />}
          />
          <Route
            path="/admin/allpost"
            element={<Masterwebpage name="AllPost" />}
          />
          <Route
            path="/admin/dashboard"
            element={<Masterwebpage name="Dashboard" />}
          />
          <Route
            path="/admin/students"
            element={<Masterwebpage name="Students" />}
          />
        </Route>
        {/* End of Admin Authoried Access */}

      </Routes>
    </div>
  );
}

export default App;
