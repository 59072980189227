import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useNavigate
import swal from "sweetalert";
import Modal from "./childComponents/popups/Modal";

const buttonName = 'Search Post';// Submit Button Name
const image_01 = 'http://127.0.0.1:8000/';

const AllPost = () => {
  const [pageLayout, setPageLayout] = useState('');
  const [pageCatigory, setPageCatigory] = useState('');
  const [activeStatus, setActiveStatus] = useState('');
  const [editPost, setEditPost] = useState('');
  const [displayMessages, setDisplayMessages] = useState('');
  
  const [btnLoading, setBtnLoading] = useState(buttonName); //to show loading process
  const [allRecords, setAllRecords] = useState([]);
  const [loading, setloading] = useState(true);


  /* All records */
  useEffect(() => {
    (async () => await tableLoad())();
  }, []);

  async function tableLoad() {
    let datas = '?';
    let messages = '';
    if ((pageLayout) && (pageLayout !== 'Please Select Page Layout')) {
      datas = datas + "pageLayout[eq]=" + pageLayout + '&';
      messages = 'LAYOUT: ' + pageLayout;
    }
    if ((pageCatigory) && (pageCatigory !== 'Please Select Page Category')) {
      datas = datas + "pageCatigory[eq]=" + pageCatigory + '&';
      messages = messages + ' | CATEGORY: ' + pageCatigory;
    }
    if ((activeStatus) && (activeStatus !== 'Please Select Status')) {
      datas = datas + "activeStatus[eq]=" + activeStatus;
      messages = messages + ' | STATUS: ' + activeStatus;
    }

    if (datas === '?') {
      datas = '';
      messages = 'ALL DATA';
    }

    setDisplayMessages(messages);
    //datas = "?pageLayout[eq]="+pageLayout+"&pageCatigory[eq]="+pageCatigory+"&activeStatus[eq]="+activeStatus;
    const result = await axios.get("api/admin/v1/post_page" + datas);
    setAllRecords(result.data);
    setBtnLoading(buttonName);
    setloading(false);
    console.log(result.data);
  }
  /* End of All records */

  /* Defualt Search Records */
  const [allRecords_02, setAllRecords_02] = useState([]); //store defualt search records

  useEffect(() => {
    (async () => await tableLoad_02())();
  }, []);

  async function tableLoad_02() {
    let datas = '?';
    if (pageLayout) {
      datas = datas + "pageLayout[eq]=" + pageLayout + '&';
    }
    if (pageCatigory) {
      datas = datas + "pageCatigory[eq]=" + pageCatigory + '&';
    }
    if (activeStatus) {
      datas = datas + "activeStatus[eq]=" + activeStatus;
    }

    if (datas === '?') {
      datas = '';
    }
    const result = await axios.get("api/admin/v1/post_page" + datas);
    setAllRecords_02(result.data);
    console.log(result.data);
  }
  /* End of Defualt Search Records */

  /* Loads the select fileds */
  const pageCatigorys = [
    { value: "", name: "Please Select Page Category" },
    { value: "Home", name: "Home" },
    { value: "Contact", name: "Contact" },
    { value: "Product", name: "Product" },
    { value: "About Us", name: "About Us" }
  ];

  const pageLayouts = [
    { value: "", name: "Please Select Page Layout" },
    { value: "Publication", name: "Publication" },
    { value: "Product", name: "Product" },
  ];

  const activeStatuss = [
    { value: "", name: "Please Select Status" },
    { value: "Active", name: "Active" },
    { value: "Draft", name: "Draft" },
    { value: "Delete", name: "Delete" },

  ];
  /* End of Loads the select fileds */

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const newRecords = allRecords.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allRecords.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  /* End of Pagination */

  /* Search */
  const handleFilter = (event) => {
    let text = event.target.value;
    tableLoad_02();
    const newData = allRecords_02.filter((row) =>
      row.pageTitle.toLowerCase().includes(text.toLowerCase())
    );
    if (event) {
      setAllRecords(newData);
      setAllRecords_02(allRecords_02);
    }
  };

  /* End of Search */
  /*Clean the Form*/
  const formCleanner = () => {
    tableLoad();
  };
  /* End Clean the Form*/


  async function updatePost(event, posts) {
    event.preventDefault();

    setEditPost(posts);
  }
  async function searchData(event) {

    event.preventDefault();
    setBtnLoading(<div><i className="fa fa-refresh fa-spin"></i></div>);
    tableLoad();
    tableLoad_02();
  }
  async function DeletePost(event, id) {
    event.preventDefault();

    await swal({
      title: "Are you sureee!!?",
      text: "Once deleted, you will not be able to recover this data againoo!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {//used to delete the file

          axios.get("sanctum/csrf-cookie").then((response) => {
            axios.delete("api/admin/v1/post_page/" + id).then((res) => {
              if (res.data.status === 200) {

                swal("Deleted", res.data.message, "success");
                console.log(res.data.message);
                formCleanner();
              } else {
                swal("Oops!", res.data.message, "warning");
                console.log(res.data.validation_error);
              }
            });
          });
        } else {
          swal("Saved", "The data is safe!", "success");
        }
      });

  }

  if (loading) {
    return <div id="loader"></div>;
  }
  return (
    <>
      {/* Model Type, Title of the Modal Box, triger the modal, convay all the data inputs to be edithed to child */}
      <Modal modelType={'ModalPost'} titles={'EDIT POST'} trigger={true} editpost={editPost}/>
      
      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><i className="fa fa-search"></i> SEARCH POST</div>
              <div className="card-body">
                <div className='row m-1'>
                  <div className='col-lg-3'>
                    <div className="form-group mt-3">
                      <label for="pageLayout" className="col-sm-12">
                        Page Layout
                      </label>
                      <div className="col-sm-12">
                        <select
                          className="form-select"
                          id="pageLayout"
                          name="pageLayout"
                          value={pageLayout}
                          onChange={(event) => {
                            setPageLayout(event.target.value);
                          }}
                        >
                          {
                            pageLayouts.map((item) => {
                              return (<option value={item.id} key={item.id}>{item.name}</option>)
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    <div className="form-group mt-3">
                      <label for="pageCatigory" className="col-sm-12">
                        Page Category
                      </label>
                      <div className="col-sm-12">
                        <select
                          className="form-select"
                          id="pageCatigory"
                          name="pageCatigory"
                          value={pageCatigory}
                          onChange={(event) => {
                            setPageCatigory(event.target.value);
                          }}
                        >
                          {
                            pageCatigorys.map((item) => {
                              return (<option value={item.id} key={item.id}>{item.name}</option>)
                            })
                          }
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    <div className="form-group mt-3">
                      <label for="activeStatus" className="col-sm-12">
                        Status
                      </label>
                      <div className="col-sm-12">
                        <select
                          className="form-select"
                          id="activeStatus"
                          name="activeStatus"
                          value={activeStatus}
                          onChange={(event) => {
                            setActiveStatus(event.target.value);
                          }}
                        >
                          {
                            activeStatuss.map((item) => {
                              return (<option value={item.id} key={item.id}>{item.name}</option>)
                            })
                          }

                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-3'>
                    <div className="form-group mt-3">

                      <Link className='btn btn-primary col-2 ms-2 mt-4' onClick={softreload} to='/admin/allpost'><i className='fa fa-refresh'></i></Link>
                      <button className='btn btn-primary col-6 ms-3 mt-4' onClick={searchData}><div className=' d-flex flex-row'><i className="fa fa-search me-1 pt-1"></i>  {btnLoading}</div></button>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container-lg-fluid m-3'>
        <div className='row'>
          <div className='col-md-12 mb-3'>
            <div className="card">
              <div className="card-header text-white bg-info p-3"><i className="fa fa-newspaper"></i> ALL POST <span className="float-end me-3">{displayMessages}</span></div>
              <div className="card-body">
                <div className='row m-1'>

                  <div>
                    <div style={{ display: "flex", float: "right" }}>
                      <input
                        type="text"
                        name="searchData"
                        placeholder="...Search"
                        style={{ padding: "5px 7px" }}
                        onChange={handleFilter}
                      />
                    </div>
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Action</th>
                        <th scope="col">Title</th>
                        <th scope="col" className="col-3">Publication</th>
                        <th scope="col">Image</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Prod. Cat.</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {newRecords.map((allRecord, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name={allRecord.id}
                              />
                            </th>

                            <td>{allRecord.pageTitle}</td>
                            <td>{removeTags(allRecord.message)}</td>
                            <td><img src={image_01 + allRecord.uploadFile} alt="" width={70} height={80} /></td>
                            <td>{allRecord.quantity}</td>
                            <td>{allRecord.price}</td>
                            <td>{allRecord.productCatigory}</td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-warning me-3"
                                data-bs-toggle="modal" data-bs-target="#exampleModal"
                                onClick={(event) => updatePost(event, allRecord)}

                              ><i className="fa fa-edit me-1"> </i>
                                Edit
                              </button>


                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={(event) => DeletePost(event, allRecord.id)}
                              ><i className="fa fa-trash me-1"> </i>
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}

                    </tbody>
                  </table>

                  <nav>
                    <ul className="pagination">
                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={prePage}>
                          Prev
                        </Link>
                      </li>

                      {number.map((n, i) => (
                        <li
                          className={`page-item ${currentPage === n ? "active" : ""}`}
                          key={i}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => changeCPage(n)}
                          >
                            {n}
                          </Link>
                        </li>
                      ))}

                      <li className="page-item">
                        <Link to="#" className="page-link" onClick={nextPage}>
                          Next
                        </Link>
                      </li>
                    </ul>
                  </nav>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )

  function removeTags(str) {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
  }

  function softreload() {
    /*
    setPageLayout('');
    setPageCatigory('');
    setActiveStatus('');
    tableLoad();
    */
    window.location.reload();
  }

  /* Pagination*/
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    if (currentPage !== firstIndex) {
      setCurrentPage(id);
    }
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  /* End of Pagination*/
}

export default AllPost
