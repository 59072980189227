import React, { useState, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import { generateDownload } from "../../../../utils/cropImage";
import swal from "sweetalert";

const Crop = (props) => {


  const [croppedArea, setCroppedArea] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const [aspectRatios, setAspectRatios] = useState(12 / 7);
  const [uploadFile, setUploadFile] = useState(1);
  //const [cropedImage, setCropedImage] = useState('');

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    console.log(croppedArea, croppedAreaPixels)
    setCroppedArea(croppedAreaPixels);
  }

  function changeZoom(event) {
    setZoom(event.target.value);
  }

  useEffect(() => {
    let ratio = 1;
    setUploadFile(props.imageCropUrl);

    if (props.aspectRatios === 'Landscape') {
      ratio = 7 / 5;
    } else if (props.aspectRatios === 'Passport') {
      ratio = 6.5 / 7;
    } else if (props.aspectRatios === 'Product') {
      ratio = 7 / 7;
    }
    setAspectRatios(ratio);

  }, [props.imageCropUrl, props.aspectRatios]);

  const closeCrop = () => {
    swal("Success", "Image Cropping Closed Successfully", "success");
    props.setCloseModal(false);
  }

  const submitImage = (event) => {
    event.preventDefault();
    let myFile = generateDownload(uploadFile, croppedArea);

    myFile.then(
      function (value) {
        props.setReturnCrop(value);
        //console.log('$$$Tesd111',value)
      },
      function (error) {
        //console.log('$$$Tesd222',error)
      }
    );


    swal("Success", "Image Cropping Successfully", "success");
    props.setCloseModal(false);

  }
  return (
    <>
      <div className='containers'>
        <div className='containers-cropper row'>
          <div className='cropper col-md-12'>
            <Cropper
              image={uploadFile}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatios}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>


          <div className='offset-md-4 bg-white'>
            <div className='col-md-4 m-3'>
              <label for="customRange3" className="form-label fw-bold"> Zoom: </label>
              <input type="range"
                className="form-range ms-2"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={changeZoom}
              />
            </div>

            <div className='col-md-4 m-3'>
              <button type='text' className='btn btn-success col-5 ms-4 me-4' onClick={submitImage} value='Crop Image'><i className='fa fa-image' > </i> Crop Image</button>
              <button type='text' className='btn btn-danger col-5 ms-4' onClick={closeCrop} value='Close'><i className='fa fa-close' > </i> Close Crop </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )

}

export default Crop
