import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import image_02 from "../../assets/admin/assets/img/users/u1.jpg";
import image_03 from "../../assets/admin/assets/img/users/u2.jpg";
import image_04 from "../../assets/admin/assets/img/users/u3.jpg";
import image_05 from "../../assets/admin/assets/img/users/u4.jpg";
import applicationLogo from "../../assets/admin/assets/img/logos/smallDynamoBanner.jpg";
import axios from "axios";
import swal from "sweetalert";

const Navbar = () => {
  const [staffImage, setStaffImage] = useState('');
  const [admin_type, setAdmin_type] = useState('');

  useEffect(() => {
    setAdmin_type(localStorage.getItem("admin_type"));
    setStaffImage(localStorage.getItem('admin_passport'));

  }, []);

  let image_01 = `http://127.0.0.1:8000/` + staffImage;

  /* 
    I use this to display and remove the side menubar 
    (document.getElementById doesnot work directly in react)
  */

  const [menuSize, setMenuSize] = useState(0);
  const navigate = useNavigate("");
  useEffect(() => {
    const sidebarToggle = document.getElementById("sidebarToggle");

    if (sidebarToggle) {
      // Uncomment Below to persist sidebar toggle between refreshes
      // if (localStorage.getItem('sb|sidebar-toggle') === 'true') {
      //     document.body.classList.toggle('sb-sidenav-toggled');
      // }
      sidebarToggle.addEventListener("click", (event) => {
        event.preventDefault();
        document.body.classList.toggle("sb-sidenav-toggled");
        localStorage.setItem(
          "sb|sidebar-toggle",
          document.body.classList.contains("sb-sidenav-toggled")
        );
      });
    }
  }, [menuSize]);

  async function logoutSubmit(event) {
    event.preventDefault();

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/admin/v1/logout").then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);

            localStorage.removeItem("admin_name");
            localStorage.removeItem("admin_authority");
            localStorage.removeItem("admin_type");
            localStorage.removeItem("admin_status");
            localStorage.removeItem("admin_passport");
            localStorage.removeItem("admin_signature");
            localStorage.removeItem("admin_id");
            localStorage.removeItem("auth_token");
            localStorage.removeItem('auth_name');
            /**/
            swal("Logout Successful", res.data.message, "success");
            navigate("/logout"); //redirect to the Dashboard page
          } else {
            swal("Warning", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
          }
        });
      } catch (error) {
        swal("Oops!", "Logout Failed", "error");
        console.log(error.response.data);
      }
    });

    setTimeout(function () {
      setMenuSize(1);
    }, 3000);
  }
  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
      <Link className="navbar-brand bg-secondary" to="/admin/dashboard">
        <img src={applicationLogo} alt="" style={{ width: "100%", height: "auto" }} />
      </Link>

      <button
        onMouseOver={() => {
          setMenuSize(1);
        }}
        className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0"
        id="sidebarToggle"
        href="#!"
      >
        <i className="fas fa-bars"></i>
      </button>

      <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
        <div className="input-group">
          <input
            className="form-control"
            name="Search"
            id="Search"
            placeholder="Search here..."
          />
        </div>
      </form>

      <ul className="navbar-nav ms-auto ms-md-0 me-0">
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            id="navbarDropdown1"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-envelope"></i>
            <span className="position-absolute translate-middle badge rounded-pill bg-primary">9</span>
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown1"
            style={{ width: "300px", borderRadius: "0px" }}
          >
            <li className="dropdown-menu-header">
              <div className="row ms-3">
                <span className="col-sm-8 p-1">
                  <strong>9 New</strong> Messages
                </span>
                <Link
                  className="pull-right p-1 col-sm-3 text-end text-decoration-none "
                  to="#"
                >
                  view all
                </Link>
              </div>
            </li>
            <li
              className="list-group list-group-divider scroller"
              data-height="240px"
              data-color="#71808f"
            >
              <div>
                <Link className="list-group-item">
                  <div className="media row">
                    <div className="media-img col-3">
                      <img src={image_02} alt="" width={50} />
                    </div>
                    <div className="media-body col-sm-9 small">
                      <div className="row">
                        <div className="font-strong col-sm-8">
                          Jeanne Gonzalez
                        </div>
                        <small className="text-muted col-sm-4 text-end">
                          Just now
                        </small>
                        <div className="font-13 col-sm-12">
                          Your proposal interested me.
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link className="list-group-item">
                  <div className="media row">
                    <div className="media-img col-sm-3">
                      <img src={image_03} alt="" width={50} />
                    </div>
                    <div className="media-body col-sm-9 small">
                      <div className="row">
                        <div className="font-strong col-sm-8">
                          {" "}
                          Becky Brooks
                        </div>
                        <small className="text-muted col-sm-4 text-end">
                          18 mins
                        </small>
                        <div className="font-13 col-sm-12">
                          Lorem Ipsum is simply.
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link className="list-group-item">
                  <div className="media row">
                    <div className="media-img col-3">
                      <img src={image_04} alt="" width={50} />
                    </div>
                    <div className="media-body col-sm-9 small">
                      <div className="row">
                        <div className="font-strong col-sm-8"> Frank Cruz</div>
                        <small className="text-muted col-sm-4 text-end">
                          18 mins
                        </small>
                        <div className="font-13 col-sm-12">
                          Lorem Ipsum is simply.
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                <Link className="list-group-item">
                  <div className="media row">
                    <div className="media-img col-sm-3">
                      <img src={image_05} alt="" width={50} />
                    </div>
                    <div className="media-body col-sm-9 small">
                      <div className="row">
                        <div className="font-strong col-sm-8">
                          {" "}
                          Rose Pearson
                        </div>
                        <small className="text-muted col-sm-4 text-end">
                          3 hrs
                        </small>
                        <div className="font-13 col-sm-12">
                          Lorem Ipsum is simply.
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="navbar-nav ms-auto ms-md-0 me-0">
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            id="navbarDropdown2"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-bell">
              <span className="position-absolute translate-middle p-2 bg-danger border border-light rounded-circle"></span>
            </i>
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown2"
            style={{ width: "300px", borderRadius: "0px" }}
          >
            <li className="dropdown-menu-header">
              <div className="row ms-3">
                <span className="col-sm-8 p-1">
                  <strong>5 New</strong> Notifications
                </span>
                <Link
                  className="pull-right p-1 col-sm-3 text-end text-decoration-none "
                  to="#"
                >
                  view all
                </Link>
              </div>
            </li>

            <li
              className="list-group list-group-divider scroller"
              data-height="240px"
              data-color="#71808f"
            >
              <div>
                <Link className="list-group-item">
                  <div className="media row">
                    <div className="media-img col-sm-3">
                      <span className="badge badge-success badge-big">
                        <i className="fa fa-check"></i>
                      </span>
                    </div>
                    <div className="media-body col-sm-9 small">
                      <div className="font-13">4 task compiled</div>
                      <small className="text-muted">22 mins</small>
                    </div>
                  </div>
                </Link>

                <Link className="list-group-item">
                  <div className="media row">
                    <div className="media-img col-sm-3">
                      <span className="badge badge-default badge-big">
                        <i className="fa fa-shopping-basket"></i>
                      </span>
                    </div>
                    <div className="media-body col-sm-9 small">
                      <div className="font-13">You have 12 new orders</div>
                      <small className="text-muted">40 mins</small>
                    </div>
                  </div>
                </Link>

                <Link className="list-group-item">
                  <div className="media row">
                    <div className="media-img col-sm-3">
                      <span className="badge badge-danger badge-big">
                        <i className="fa fa-bolt"></i>
                      </span>
                    </div>
                    <div className="media-body col-sm-9 small">
                      <div className="font-13">Server #7 rebooted</div>
                      <small className="text-muted">2 hrs</small>
                    </div>
                  </div>
                </Link>

                <Link className="list-group-item">
                  <div className="media row">
                    <div className="media-img col-sm-3">
                      <span className="badge badge-success badge-big">
                        <i className="fa fa-user"></i>
                      </span>
                    </div>
                    <div className="media-body col-sm-9 small">
                      <div className="font-13">New user registered</div>
                      <small className="text-muted">2 hrs</small>
                    </div>
                  </div>
                </Link>
              </div>
            </li>
          </ul>
        </li>
      </ul>

      <ul className="navbar-nav ms-auto ms-md-0 me-3">
        <li className="nav-item dropdown">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            id="navbarDropdown3"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img src={image_01} alt="" width="30" height="30" className='rounded-circle me-3' style={{ border: "white 2px solid" }} />
            <span style={{ fontSize: "12px" }}>{admin_type}</span>
          </Link>
          <ul
            className="dropdown-menu dropdown-menu-end"
            aria-labelledby="navbarDropdown3"
            style={{ borderRadius: "0px" }}
          >
            <li>
              <Link className="dropdown-item" to="profile.html">
                <i className="fa fa-user"></i> Profile
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="profile.html">
                <i className="fa fa-cog"></i> Settings
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="#">
                <i className="fa fa-support"></i> Support
              </Link>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <Link className="dropdown-item" onClick={logoutSubmit}>
                <i className="fa fa-power-off"></i> Logout
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};
export default Navbar;
