import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; //useNavigate
import swal from "sweetalert";
const Students = () => {
  //const navigate = useNavigate("");
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [summary, setSummary] = useState("");
  const [allRecords, setAllRecords] = useState([]);
  const [loading, setloading] = useState(true);
  

  /* All records */
  useEffect(() => {
    (async () => await tableLoad())();
  }, []);

  async function tableLoad() {
    const result = await axios.get("api/admin/v1/home_page");
    setAllRecords(result.data);
    setloading(false);
    console.log(result.data);
  }
  /* End of All records */

  /*Clean the Form*/

  const formCleanner = () => {
    setId("");
    setFirstName("");
    setLastName("");
    setAge("");
    setHouseNo("");
    setSchoolName("");
    setSummary("");
    tableLoad();
  };
  /* End Clean the Form*/

  /* Defualt Search Records */
  const [allRecords_02, setAllRecords_02] = useState([]); //store defualt search records

  useEffect(() => {
    (async () => await tableLoad_02())();
  }, []);

  async function tableLoad_02() {
    const result = await axios.get("api/admin/v1/home_page");
    setAllRecords_02(result.data);
    console.log(result.data);
  }
  /* End of Defualt Search Records */
  const [dropZoneClick, setDropZoneClick] = useState(0);

  useEffect(() => {
    (async () => await dropZoneUpload())();
  }, [dropZoneClick]);
  /**/

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const newRecords = allRecords.slice(firstIndex, lastIndex);
  const npage = Math.ceil(allRecords.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);
  /* End of Pagination */

  /* Search */
  const handleFilter = (event) => {
    let text = event.target.value;
    tableLoad_02();
    const newData = allRecords_02.filter((row) =>
      row.firstName.toLowerCase().includes(text.toLowerCase())
    );
    if (event) {
      setAllRecords(newData);
      setAllRecords_02(allRecords_02);
    }
  };

  /* End of Search */

  async function registerStudent(event) {
    event.preventDefault();

    const datas = {
      firstName: firstName,
      lastName: lastName,
      age: age,
      schoolName: schoolName,
      houseNo: houseNo,
      summary: summary,
    };
    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios.post("api/admin/v1/home_page", datas).then((res) => {
          if (res.data.status === 200) {
            console.log(res.data.message);
            /*
            localStorage.setItem('auth_token', res.data.token);
            localStorage.setItem('auth_name', res.data.firstName);
            */
            swal("Success", res.data.message, "success");

            //navigate('/'); //redirect to the home page
            formCleanner();
          } else {
            swal("Failed", res.data.message, "warning");
            console.log(res.data.message);
            console.log(res.data.validation_error);
          }
        });
      } catch (error) {
        swal("Oops!", "Student Registation Failed", "error");
        console.log(error.response.data);
      }
    });
  }

  async function submitUpload(event) {
    event.preventDefault();
    
    /*
    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios
          .put(
            "api/admin/v1/home_page/" +
              allRecords.find((u) => u.id === id).id || id,
            {
              firstName: firstName,
              lastName: lastName,
              age: age,
              schoolName: schoolName,
              houseNo: houseNo,
              summary: summary,
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              swal("Success", res.data.message, "success");
              console.log(res.data.message);
              formCleanner();
            } else {
              swal("Oops!", res.data.message, "warning");
              console.log(res.data.validation_error);
            }
          });
      } catch (error) {
        swal("Oops!", "Student Update Failed", "error");
        console.log(error.response.data);
      }
    });
    */
  }

  async function updateStudent(event) {
    event.preventDefault();

    await axios.get("sanctum/csrf-cookie").then((response) => {
      try {
        axios
          .put(
            "api/admin/v1/home_page/" +id,
            {
              firstName: firstName,
              lastName: lastName,
              age: age,
              schoolName: schoolName,
              houseNo: houseNo,
              summary: summary,
            }
          )
          .then((res) => {
            if (res.data.status === 200) {
              swal("Success", res.data.message, "success");
              console.log(res.data.message);
              formCleanner();
            } else {
              swal("Oops!", res.data.message, "warning");
              console.log(res.data.validation_error);
            }
          });
      } catch (error) {
        swal("Oops!", "Student Update Failed", "error");
        console.log(error.response.data);
      }
    });
  }

  async function editStudent(event, students) {
    event.preventDefault();

    setFirstName(students.firstName);
    setLastName(students.lastName);
    setAge(students.age);
    setSchoolName(students.schoolName);
    setHouseNo(students.houseNo);
    setSummary(students.summary);
    setId(students.id);
  }

  async function DeleteStudent(event, id) {
    event.preventDefault();
    await axios.get("sanctum/csrf-cookie").then((response) => {
      axios.delete("api/admin/v1/home_page/" + id).then((res) => {
        if (res.data.status === 200) {
          swal("Deleted", "Student deleted Successfully", "success");
          console.log(res.data.message);
          formCleanner();
        } else {
          swal("Oops!", res.data.message, "warning");
          console.log(res.data.validation_error);
        }
      });
    });
  }

  setTimeout(function () {
    setDropZoneClick(1);
  }, 3000);

  if (loading) {
    return <div id="loader"></div>;
  }

  return (
    <>
      <div className="container-lg">
        <h1 className="m-5">This is my first React Code</h1>
        <form encType="multipart/form-data" onSubmit={submitUpload}>
          <div className="row m-1">
            <div className="col-sm-4">
              <div className="form-group row">
                <label for="firstName" className="col-sm-12">
                  First Name
                </label>
                <div className="col-sm-12">
                  <input
                    hidden
                    type="text"
                    className="form-control"
                    id="student_id"
                    value={id}
                    onChange={(event) => {
                      setId(event.target.value);
                    }}
                  />

                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder="Enter First Name"
                    value={firstName}
                    onChange={(event) => {
                      setFirstName(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row mt-3">
                <label for="age" className="col-sm-12">
                  Age
                </label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    id="age"
                    placeholder="Enter Age"
                    value={age}
                    onChange={(event) => {
                      setAge(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row mt-3">
                <label for="houseNo" className="col-sm-12">
                  House Number
                </label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    id="houseNo"
                    placeholder="Enter House Number"
                    value={houseNo}
                    onChange={(event) => {
                      setHouseNo(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group row">
                <label for="lastName" className="col-sm-12">
                  Last Name
                </label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder="Enter Last Name"
                    value={lastName}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row mt-3">
                <label for="schoolName" className="col-sm-12">
                  School Name
                </label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    id="schoolName"
                    placeholder="Enter School Name"
                    value={schoolName}
                    onChange={(event) => {
                      setSchoolName(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row mt-3">
                <label for="summary" className="col-sm-12">
                  Summary
                </label>
                <div className="col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    id="summary"
                    placeholder="Enter Summary"
                    value={summary}
                    onChange={(event) => {
                      setSummary(event.target.value);
                    }}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-primary m-3"
                onClick={registerStudent}
              >
                Register
              </button>
              <button
                type="submit"
                className="btn btn-warning  m-3"
                onClick={updateStudent}
              >
                Update
              </button>
            </div>

            <div className="col-sm-3 ms-5">
              <div className="form-group row">

                <div className="drop-zone" onMouseOver={() => {
                  setDropZoneClick(1);
                }}  >
                  <span className="drop-zone__prompt">
                    Drop file here or click to upload
                  </span>
                  <input
                    type="file"
                    name="myFile"
                    className="drop-zone__input"
                  />
                </div>

              </div>
            </div>
          </div>
        </form>
        <div>
          <div style={{ display: "flex", float: "right" }}>
            <input
              type="text"
              name="searchData"
              placeholder="...Search"
              style={{ padding: "5px 7px" }}
              onChange={handleFilter}
            />
          </div>
        </div>
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col">Action</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Age</th>
              <th scope="col">House No</th>
              <th scope="col">School Name</th>
              <th scope="col">Summary</th>
              <th scope="col">Options</th>
            </tr>
          </thead>
          <tbody>
            {newRecords.map((allRecord, index) => {
              return (
                //firstName, lastName, age, schoolName, houseNo, summary

                <tr key={index}>
                  <th scope="row">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name={allRecord.id}
                    />
                  </th>
                  <td>{allRecord.firstName}</td>
                  <td>{allRecord.lastName}</td>
                  <td>{allRecord.age}</td>
                  <td>{allRecord.houseNo}</td>
                  <td>{allRecord.schoolName}</td>
                  <td>{allRecord.summary}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={(event) => editStudent(event, allRecord)}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={(event) => DeleteStudent(event, allRecord.id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <nav>
          <ul className="pagination">
            <li className="page-item">
              <Link to="#" className="page-link" onClick={prePage}>
                Prev
              </Link>
            </li>

            {number.map((n, i) => (
              <li
                className={`page-item ${currentPage === n ? "active" : ""}`}
                key={i}
              >
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => changeCPage(n)}
                >
                  {n}
                </Link>
              </li>
            ))}

            <li className="page-item">
              <Link to="#" className="page-link" onClick={nextPage}>
                Next
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );

  /* Pagination*/
  function prePage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }
  function changeCPage(id) {
    if (currentPage !== firstIndex) {
      setCurrentPage(id);
    }
  }
  function nextPage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }
  /* End of Pagination*/

  async function dropZoneUpload() {
    document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
      const dropZoneElement = inputElement.closest(".drop-zone");

      dropZoneElement.addEventListener("click", (e) => {
        inputElement.click();
      });

      inputElement.addEventListener("change", (e) => {
        if (inputElement.files.length) {
          updateThumbnail(dropZoneElement, inputElement.files[0]);
        }
      });

      dropZoneElement.addEventListener("dragover", (e) => {
        e.preventDefault();
        dropZoneElement.classList.add("drop-zone--over");
      });

      ["dragleave", "dragend"].forEach((type) => {
        dropZoneElement.addEventListener(type, (e) => {
          dropZoneElement.classList.remove("drop-zone--over");
        });
      });

      dropZoneElement.addEventListener("drop", (e) => {
        e.preventDefault();

        if (e.dataTransfer.files.length) {
          inputElement.files = e.dataTransfer.files;
          updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
        }

        dropZoneElement.classList.remove("drop-zone--over");
      });
    });

    /**
     * Updates the thumbnail on a drop zone element.
     *
     * @param {HTMLElement} dropZoneElement
     * @param {File} file
     */
    function updateThumbnail(dropZoneElement, file) {
      let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

      // First time - remove the prompt
      if (dropZoneElement.querySelector(".drop-zone__prompt")) {
        dropZoneElement.querySelector(".drop-zone__prompt").remove();
      }

      // First time - there is no thumbnail element, so lets create it
      if (!thumbnailElement) {
        thumbnailElement = document.createElement("div");
        thumbnailElement.classList.add("drop-zone__thumb");
        dropZoneElement.appendChild(thumbnailElement);
      }

      thumbnailElement.dataset.label = file.name;

      // Show thumbnail for image files
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
        };
      } else {
        thumbnailElement.style.backgroundImage = null;
      }
    }
  }

};

export default Students;
